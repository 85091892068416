.custom__card {
    background-color: var(--card-color);
    border-radius: 10px;
    box-shadow: 0px 2px var(--card-box-shadow);
    margin-inline: auto;
    padding-inline: 0.5rem;
    padding-top: 0.2rem;
    padding-bottom: 0.4rem;
    /* cursor: grab; */
    color: var(--text-color);
    margin-bottom: 10px;
    /* position: relative; */
  }
  
  .card__tags {
    display: flex;
    column-gap: 5px;
    row-gap: 5px;
    flex-wrap: wrap;
    align-self: flex-start;
  }
  
  .card__text {
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    font-size: 14px;
    font-weight: 600;
  }
  
  .car__more {
    opacity: 0.5;
    cursor: pointer;
  }
  
  .car__more:hover {
    opacity: 1;
    cursor: pointer;
    transition: ease-in 100ms;
  }
  
  .card__dropdown {
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.12);
    cursor: default;
  }
  
  .card__dropdown>p {
    border-bottom: 1px solid #f8f8f8;
    cursor: pointer;
  }
  
  .card__footer {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
  }
  
  .card__footer>div>svg {
    height: 15px;
    width: 15px;
    color: rgb(116, 116, 116);
    cursor: pointer;
  }
  
  .card__footer div {
    display: flex;
    align-items: center;
  }
  
  .card__footer span {
    font-size: 12px;
    color: rgb(116, 116, 116);
    margin-inline: 2px;
    font-weight: 500;
  }