/***** input *****/

*:focus,
input:focus,
input.form-control:focus,
.form-control:focus,
.form-select:focus,
.accordion-button:focus {

    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}


.form-control,
.form-select {
    font-size: 14px;
    font-weight: 600;
    border-color: #D9DBDA !important;
    border-radius: 10px;
    /* box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); */
    /* box-shadow: 0 1px 3px 0 #d4d9e1; */
}

.form-control:focus,
.form-control[focus],
.form-select:focus {
    border-color: #D9DBDA !important;
}


.form-check-input {
    width: 16px;
    height: 16px;
}

.form-check-input:checked {
    background-color: #CAB05D;
    border-color: #CAB05D;
}

/*************** terminal-tree-css ***************/


.card {
    &.card-terminal-tree {
        background-color: #ffffff;
        border: 1px solid #D9DBDA;
        border-radius: 10px;
    }
}

.terminal-tree {
    font-size: 16px;
    user-select: none;
    min-height: 320px;
    padding: 20px;
    box-sizing: content-box;
}

.terminal-tree .tree,
.terminal-tree .tree-node,
.terminal-tree .tree-node-group {
    list-style: none;
    margin: 0;
    padding: 0;
}

.terminal-tree .tree-branch-wrapper,
.terminal-tree .tree-node__leaf {
    outline: none;
}

.terminal-tree .tree-node {
    cursor: pointer;
}

.terminal-tree .tree-node .name:hover {
    background: rgba(0, 0, 0, 0.1);
}

.terminal-tree .tree-node--focused .name {
    background: rgba(0, 0, 0, 0.2);
}

.terminal-tree .tree-node {
    display: inline-block;
}

.terminal-tree .checkbox-icon {
    margin: 0 5px;
    vertical-align: middle;
}

.terminal-tree button {
    border: none;
    background: transparent;
    cursor: pointer;
}

.terminal-tree .arrow {
    margin-left: 5px;
    vertical-align: middle;
}

.terminal-tree .arrow--open {
    transform: rotate(90deg);
}








.form-check-input:focus {
    border-color: #F0E8BC;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgb(240 232 188 / 50%);
}

.form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23CAB05D'/%3e%3c/svg%3e");
}


input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    color: transparent;
    cursor: pointer !important;
    height: auto;
    width: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
}


input:focus-visible {
    outline: -webkit-focus-ring-color auto 0px !important;
}

.export-th-btn {
    top: 9px;
    right: 6px;
}

/***** Chrome, Safari, Edge, Opera *****/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/***** Firefox *****/

input[type=number] {
    -moz-appearance: textfield;
}



.form-floating>.form-control:not(:placeholder-shown)~label::after {
    background-color: transparent !important;
}



/***** input-autofill css start *****/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: #ffffff; */
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    transition: background-color 5000s ease-in-out 0s;
}

/***** input-autofill css end *****/

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
}

/***** font size and text colors and bg colors *****/

.fs-10 {
    font-size: 10px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.bg-primary {
    background-color: #0d6efd !important;
}

.bg-secondary {
    background-color: #6c757d !important;
}

.bg-success {
    background-color: #198754e0 !important;
}

.bg-danger {
    background-color: #dc3546e5 !important;
}

.bg-warning {
    background-color: #CAB05D !important;
}

.bg-info {
    background-color: #0dcaf0 !important;
}

.bg-light {
    background-color: #f8f9fa !important;
}

.bg-dark {
    background-color: #212529 !important;
}

.text-primary {
    color: #2196F3 !important;
}

.text-secondary {
    color: #ABABAB !important;
}

.text-light {
    color: #D9DBDA !important;
}

.text-warning {
    color: #CAB05D !important;
}

.text-success {
    color: #198754 !important;
}

.text-danger {
    color: #DC3545 !important;
}

.bg-light-yellow {
    background-color: #CAB05D !important;
    border-color: #877849 !important;
}

.bg-light-red {
    background: #dc3546e5 !important;
    border-color: #b32b38 !important;
}

.bg-light-green {
    background: #198754e0 !important;
    border-color: #115c39 !important;
}

.bg-light-blue {
    background: #ABABAB !important;
    border-color: #ABABAB !important;
}

.bg-light-grey {
    background: #d9dbdab4 !important;
    border-color: #D9DBDA !important;
}


/***** Badge *****/

.badge {
    border-radius: 10px !important;
}


// ------- login css -------- //


.text-clr-grey {
    color: #2c3542;
}

.master-login {
    height: 100%;
    // position: absolute;
    width: 100%;
    background: linear-gradient(226deg, #506070 0%, #2c3542 100%);
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-text {
        font-size: 30px;
        color: #f2484b;
        // line-height: 1;
        text-transform: capitalize !important;
        font-weight: 500;

        .food {
            // font-family: cursive;
            color: #9bb353;
        }
    }

    .master-content {
        background-color: rgb(255, 255, 255);
        // background-color: rgba(255, 255, 255, 0.769);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px;
        width: 500px;
        margin: 0px auto;
        overflow: hidden;
        z-index: 12;
        // position: absolute;
        // padding: 30px 70px 20px 70px;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);

        @media (max-width: 480px),
        (max-width: 980px) {
            width: 400px;
            // padding: 30px 40px 20px 40px;
        }

        .form-check-input {
            margin-top: 1px;
            margin-right: 5px;
        }

        .error-message {
            text-transform: capitalize;
            // line-height: 10px;
            font-size: 12px;
            // font-weight: 300;
            border-radius: 10px;
            // background-image: linear-gradient(180deg, #F44336 0%, #B71C1C 100%);
        }

        .error-box {
            height: 30px;
            display: flex;

            align-items: center;
            justify-content: center;
        }

        .login-label {
            position: absolute;
            left: 26px;
            // left: 35px;
            // color: #8a8a8a;
        }

        .login-input {
            // border: none;
            border-left: 0;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            // background-color: #E8EAF4 !important;
            padding-left: 0;
        }

        .login-input:focus {
            box-shadow: none;
        }

        .span-user {
            border-right: 0;
            // border: 0;
            // background: #e8eaf4;
            background: #fff;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            font-size: 14px;
            border-radius: 10px 0px 0px 10px;
        }

        .view-password {
            position: absolute;
            right: 0;
            top: 16px;
            cursor: pointer;

            i {
                font-size: 14px;
            }
        }

        .form-actions .btn {
            // font-weight: 500;
            // padding: 8px 18px;
            // border-radius: 25px;
            // background: #ffc119;
            // width: 100%;
            // color: #2c3542;
            // border-color: #ffc119;
            // background: linear-gradient(180deg, #b4d356 0%, #618001 100%);
            // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
        }

        .form-check-label {
            color: #ABABAB !important;
        }

        .rememberme {
            display: inline-block;
            position: relative;
            cursor: pointer !important;
            font-size: 14px;
            -webkit-transition: all .3s;
            -moz-transition: all .3s;
            -ms-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;
            padding-left: 18px;
            color: #8a8a8a;


            input {
                accent-color: #ffc119;
                position: absolute;
                top: 3px;
                left: 0;
            }
        }

        .forget-password {
            font-size: 14px;
            color: #8a8a8a;
            cursor: pointer !important;
        }
    }
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s !important;
}


.area {
    background: transparent;
    // background: #4e54c8;
    // background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;

}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 30s linear infinite;
    bottom: -150px;

}

.circles li:nth-child(1) {
    left: 25%;
    width: 170px;
    height: 170px;
    animation-delay: 0s;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 130px;
    height: 130px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 50px;
    height: 50px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 90px;
    height: 90px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 100px;
    height: 100px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 40px;
    height: 40px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 12s;
}

li.circle-spin {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

/* .circle-spin {
    display: flex;
    justify-content: center;
  } */

.circle-img {
    width: 90%;
    /* position: fixed; */
}

@keyframes animate {

    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 50%;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

/***** using popups  *****/

.view_password {
    position: absolute;
    right: 0;
    top: 16px;
    cursor: pointer;

    i {
        font-size: 14px;
    }
}







/*****  Tab Buttons  *****/

.card-tabs {
    border: none;

    .nav-link {
        border-radius: 0;
        background: transparent;
        padding: 18px 15px 10px;
        font-weight: 600;
        border-bottom: 1px solid #D9DBDA !important;
        font-size: 14px;
        border: 0;
        color: #ABABAB;
        position: relative;
        min-width: 140px;
        height: 56px;
        text-align: center;

        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 0px;
            width: 100%;
            background: #CAB05D;
            content: "";
        }

        &.active {
            color: #000;

            &:after {
                height: 3px;
            }
        }
    }

    .nav-item {
        &.show .nav-link {
            color: #000;
        }
    }

}


/*****  Button  *****/

.light-btn.btn-primary.h-56 {
    height: 56px !important;
}

.btn-primary {
    &.light-btn {
        /* background-color: #F2F7FD;
    border-color: #F2F7FD; */
        background-color: #ffff;
        border-color: #D9DBDA;
        color: #000;
        padding: 10px;
        border-radius: 10px;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.5;
        /* display: flex;
    align-items: center;
    justify-content: center; */
        height: 46px;
        /* box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(0, 0, 0, 0.075); */

        &:hover {
            background-color: #ffff;
            border-color: #D9DBDA;
            color: #000;
        }

        &.disabled,
        &:disabled {
            opacity: 1;
            color: #ABABAB !important;
            background-color: #ffff;
            border-color: #D9DBDA;
        }

        &:focus-visible {
            box-shadow: 0 0 0 .25rem rgb(240 232 188 / 50%);
        }

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        &.show>.btn-primary.dropdown-toggle {
            color: #000;
            background-color: #ffff;
            border-color: #D9DBDA;
        }
    }
}


/* ****** Table ***** */

tbody,
td,
tfoot,
th,
thead,
tr {
    vertical-align: middle;
    font-size: 14px;
    font-weight: 600;
}

table {
    &.inso-table {
        color: #000;
        white-space: nowrap;
        border-radius: 10px !important;
        background-color: #fff;
        /* box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.04); */
        /* border-color:  #D9DBDA; */
        border: 1px solid #D9DBDA;
        border-spacing: 0;
        border-collapse: separate;
        overflow-x: hidden;

        .badge {
            width: 80px;
        }

        thead th {
            color: #000;
            white-space: nowrap;
            vertical-align: bottom;
            background-color: #d9dbdab4;
        }

        thead th,
        tbody td {
            /* border-bottom: 1px solid #f0f1f5; */
            padding: 16px 15px;
        }

        th:first-child,
        td:first-child {
            padding-left: 30px;
        }

        th:last-child,
        td:last-child {
            padding-right: 30px;
        }

        tbody>tr:last-child>td {
            border: none !important;
        }

        tbody>tr:last-child>td {
            border: none !important;
        }



        .dropdown-toggle {
            padding: 0;
            background-color: transparent !important;
            color: #000;
            border: 0;
            font-size: 14px;
            font-weight: 600;
        }

        .dropdown-item.active {
            color: #000;
            background-color: #CAB05D;
        }


    }


}

.table-pagination {
    /* display: flex;
    align-items: center;
    justify-content: end; */

    .form-select {
        width: unset !important;
        background-color: #ffffff;
        border-color: #D9DBDA !important;
        color: #000;
        padding: 8px 35px 8px 15px;
        border-radius: 10px;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.5;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 46px;
    }
}



.table_btn {
    .arrow_btn {
        display: flex;
        justify-content: center;

        &::after {
            width: 18px;
            height: 18px;
            background-image: url(./assets/images/down-chevron.png);
            content: "";
            background-repeat: no-repeat;
            background-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotate(-90deg);
            transition: transform 0.2s ease;
        }

        &.expanded {
            &::after {
                transform: rotate(0deg);
            }
        }

    }
}

/***** Pop-up *****/

.modal-dialog {

    .modal-header,
    .modal-body,
    .modal-footer {
        padding: 1.875rem;
    }

    .modal-content {
        border-radius: 0.625rem;
        border: none;
    }

    .btn-close:focus {
        outline: 0;
        box-shadow: 0 0 0 .25rem rgb(240 232 188 / 50%);
        opacity: 1;
    }
}


/***** Dropdown *****/

.dropdown {

    .dropdown-toggle {
        // padding: 20px 18px;
        /* padding: 0px; */
        display: flex;
        align-items: center;
        justify-content: center;
        color: inherit;
        background: #ffff;
        border-radius: 10px;
        /*  width: 145px;*/

        &:after {
            content: "\f107";
            font-family: FontAwesome;
            border: 0;
            vertical-align: middle;
            margin-left: 0.5em;
            line-height: 1;
            color: inherit;
            font-size: 16px;
            /*  margin: 0 0.5em;*/
        }

    }

    .dropdown-menu {
        position: absolute;
        font-size: 14px;
        font-weight: 600;
        border: 0;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        // box-shadow: 0 10px 40px 0 rgba(32, 28, 69, 0.1);
        margin-top: 0;
        border-radius: 10px;
        overflow: hidden;

        .dropdown-item {
            font-size: 14px;
            font-weight: 600;

            &:focus,
            &:hover {
                background-color: transparent;
                color: #CAB05D;
                transition: all 0.3s ease-in-out;
            }

            &:active,
            &.active {
                background-color: transparent;
                color: #CAB05D;
                transition: all 0.3s ease-in-out;
            }
        }
    }
}


/***** Search *****/

.search_bar {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .form-label {
        position: absolute;
        left: 26px;
        // left: 35px;
        // color: #8a8a8a;
    }

    .search_input {
        // border: none;
        border-left: 0;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        // background-color: #E8EAF4 !important;
        padding-left: 0 !important;
        height: 56px;

        &:focus {
            box-shadow: none;
        }
    }

    .search_icon {
        border-right: 0;
        // border: 0;
        // background: #e8eaf4;
        background: #fff;

        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        font-size: 16px;
        // font-size: 24px;
        padding: 1rem .75rem;
        height: 58px;
        border-color: #D9DBDA !important;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .inabled-search-field {
        background-color: #ffffff;
    }

    .disabled-search-field {
        background-color: #e9ecef;
    }
}

.powered-text {
    color: #808080;
}

/***** Toast Message *****/

.ToastMessage {

    .warning {
        color: #CAB05D !important;
    }

    .success {
        color: #198754 !important;
    }

    .danger {
        color: #DC3545 !important;
    }

    .toast {
        width: 400px;
        max-width: 100%;
        font-size: 14px;
        border-radius: 10px;
        border: 0;

        .toast_container {
            display: flex;
            align-items: center;


            &:first-child {
                .svg_icon {
                    font-size: 26px;
                    margin-right: 10px;
                    align-self: start;
                    margin-top: 9px;
                }
            }

            &:last-child {
                p {
                    margin: 0px;
                    font-weight: 600;

                    &:first-child {
                        font-size: 16px;
                        // color: #000000;
                    }

                    &:last-child {
                        // color: #ABABAB;
                        font-size: 14px;
                    }
                }
            }


            .close_icon {
                background-color: transparent !important;
                font-size: 22px;
                // color: #656565;
                line-height: 0;
                padding: 0px;
                border: none;
                margin-left: auto;
                align-self: baseline;
            }

        }

    }

}

/***** Floating Input *****/

.form-floating {

    .active {
        background-color: #CAB05D !important;
        border-color: #877849 !important;
    }

    .date-icon {
        font-size: 14px;
        position: absolute;
        right: 18px;
        bottom: 9px;
        cursor: pointer !important;
        // color: #ABABAB;
        color: #000;
    }

}


/***** hover edit icon *****/

.pro-main {
    margin: 0 auto;
    margin-bottom: 1rem;
    position: relative;
    width: fit-content;

    .pro-menu-h {
        position: relative;
        width: 230px;
        height: 130px;
        margin: 0;
        margin-top: 0px;
        // overflow: hidden;
        border-radius: 10px;
        border: 2px dashed #D9DBDA;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;


        &:hover .pro-overlay {
            opacity: 1;
        }

        .pro-image {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }


        .pro-overlay {
            background: #00000016;
            backdrop-filter: blur(2px);
            position: absolute;
            height: 130px;
            width: 230px;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
            margin: auto;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            color: #ffff;
            -webkit-transition: all 0.4s ease-in-out 0s;
            -moz-transition: all 0.4s ease-in-out 0s;
            transition: all 0.4s ease-in-out 0s;
        }


    }

    .pro-menu {
        position: relative;
        width: 130px;
        height: 130px;
        margin: 0;
        margin-top: 0px;
        // overflow: hidden;
        border-radius: 10px;
        border: 2px dashed #D9DBDA;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;


        &:hover .pro-overlay {
            opacity: 1;
        }

        .pro-image {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }


        .pro-overlay {
            background: #00000016;
            backdrop-filter: blur(2px);
            position: absolute;
            height: 100px;
            width: 100px;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
            margin: auto;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            color: #ffff;
            -webkit-transition: all 0.4s ease-in-out 0s;
            -moz-transition: all 0.4s ease-in-out 0s;
            transition: all 0.4s ease-in-out 0s;
        }


    }

    .pro-trash {
        text-align: center;
        font-size: 20px;
        cursor: pointer;
    }
}


.pro-menu {
    position: relative;
    width: 130px;
    height: 130px;
    margin: auto;
    margin-bottom: 1.5rem;
    margin-top: 0px;
    // overflow: hidden;
    border-radius: 10px;
    border: 2px dashed #D9DBDA;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;


    &:hover .pro-overlay {
        opacity: 1;
    }

    .pro-image {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }


    .pro-overlay {
        background: #00000016;
        backdrop-filter: blur(2px);
        position: absolute;
        height: 100px;
        width: 100px;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        margin: auto;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: #ffff;
        -webkit-transition: all 0.4s ease-in-out 0s;
        -moz-transition: all 0.4s ease-in-out 0s;
        transition: all 0.4s ease-in-out 0s;
    }


}

/*****  profile page  *****/


.profile-img {
    position: relative;
    z-index: 1;
    margin-left: -5px;
    margin-top: -90px;
    border: 5px solid #ffff;
}

// .profile-bg {
//     background: url(./assets/images/cover.jpeg);
//     background-size: cover;
//     background-position: center;
//     min-height: 200px;
//     width: 100%;
// }


/***** Loader *****/

.spinner-img {
    // background: #0000001a;
    width: 100%;
    height: calc(99vh - (45px + 65px));
    backdrop-filter: blur(5px);
    position: absolute;
    z-index: 1000;
    pointer-events: none !important;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // margin: 8% auto !important;
    }

}


.loading-img {
    display: block !important;
    margin: 10% auto !important;
    width: 20%;
}


/***** Notification Popover *****/

.notificationPopover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border: none;

    .popover-arrow::before {
        border-top-color: transparent;
    }

    span {
        font-size: 14px;
        font-family: 'Quicksand', sans-serif;
        font-weight: 500;
        line-height: 20px;
        word-wrap: break-word;
    }
}


/***** Auto Completer *****/
.main_search_bar {
    position: relative;
    width: 100%;

    .search_icon {
        align-items: center;
        display: flex;
        bottom: 0;
        justify-content: center;
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 2.5rem;
        z-index: 1;
        font-size: 16px;

    }

    .type-input {
        .form-control {
            padding-left: 2.5rem;
            height: 56px;
            width: 100%;
        }
    }

    .has-aux {
        .form-control {
            padding-right: 2.5rem;
        }
    }

    .rbt-aux {
        width: 2.5rem;

        .rbt-close {
            margin: 0px;

            .rbt-close-content {
                visibility: hidden;
            }
        }
    }

    .rbt-menu {
        border-radius: 10px;
        margin-top: 1px;
    }
}

.checkbox .tree,
.checkbox .tree-node,
.checkbox .tree-node-group {
    list-style: none !important;
    margin: 0;
    padding: 0;
}