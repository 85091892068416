:root {
    --text-color: #212529;
    --board-color: #f9fafb;
    --card-box-shadow:  rgba(0, 0, 0, 0.125);
    --card-color: #fff;
    --drop-down-color: rgba(0, 0, 0, 0.125);
  }
  
  .board {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 250px;
    word-wrap: break-word;
    background-color: var(--board-color);
    background-clip: border-box;
    /* border: 1px solid var(--drop-down-color); */
    border-radius: 0.625rem;
    /* border-radius: 0.25rem; */
    height: 100%;
    max-height: 82vh;
    color: var(--text-color);
    /* padding-inline: 10px; */
    /* box-shadow: 0px 12px 23px 0px rgba(62, 63, 122, 0.04); */
    box-shadow: 0 1px 3px 0 #d4d9e1;
    box-sizing: border-box;
  }
  
  .board__footer {
    display: flex;
    flex-direction: column;
  }
  
  .board__cards {
    padding: 0.5rem 0.5rem;
    overflow-y: auto;
  }
  
  .board__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    margin-bottom: 10px;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.125); */
  }
  
  .board__top .board__title {
    margin-bottom: 0;
    font-size: 14px;
  }
  
  .board__dropdown {
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.12);
    cursor: default;
  }
  
  .board__dropdown>p {
    border-bottom: 1px solid var(--drop-down-color);
    cursor: pointer;
  }
  
  .board__top>div>p:hover {
    cursor: pointer;
  }
  
  .emoji {
    margin-inline-end: 0.5rem;
  }
  
  .board__top>div>p {
    font-weight: 600;
  }
  
  .total__cards {
    font-size: 12px;
    border-radius: 15px 15px 15px 15px;
    padding-inline: 0.6rem;
    border: 0.5px solid var(--card-box-shadow);
    background-color: var(--card-color);
    margin-inline: 10px;
    font-weight: 500;
  }
  
  .title__input {
    font-weight: 400;
    /* padding-inline: 0.5rem; */
    height: 30px;
    border-radius: 5px;
  }
  
  .board__top>div {
    cursor: pointer;
  }
  
  /* .board__top > input {
  } */