body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.App {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--background-color);
    transition: all 350ms;
}

.app_outer {
    flex: 1;
    width: 100%;
    overflow-x: auto;
    margin-bottom: 1.875rem;
    background-color: #fff;
    transition: all .5s ease-in-out;
    position: relative;
    border: 0px solid transparent;
    border-radius: 1.25rem;
    box-shadow: 0px 12px 23px 0px rgba(62, 63, 122, 0.04);
}

.app_boards {
    margin-top: 20px;
    min-width: fit-content;
    display: flex;
    gap: 18px;
    /*  padding-inline: 2rem;  */
    padding-inline: 20px;
    color: var(--text-color);
}

.add__board>textarea {
    border: 0.5px solid rgb(167, 167, 167) !important;
    height: 2rem !important;
}

.card_detail>textarea {
    border: 0.5px solid rgb(167, 167, 167) !important;
}

.card__values>p {
    background: #ccc !important;
}

.cardinfo__editable {
    width: fit-content;
}

:not(:root):fullscreen::backdrop {
    background: #f9f9f9;
}

.bg-dine {
    /* color: #052c65 !important;     */
    color: #000 !important;
    background: #e7f1ff !important;
    /* border-color: #9ec5fe !important; */
}

.bg-pick {
    /* /color: #0a3622 !important;     */
    color: #000 !important;
    background: #e8f3ee !important;
    /* border-color: #a3cfbb !important; */
}

.bg-delivery {
    /* color: #58151c !important;     */
    color: #000 !important;
    background: #fbebed !important;
    /* border-color: #f1aeb5 !important; */
}

.bg-drive {
    /* color: #664d03 !important;     */
    color: #000 !important;
    background: #fff9e6 !important;
    /* border-color: #664d03 !important; */
}


.divider {
    border-right: 1px solid rgba(0, 0, 0, 0.175);
    height: 15px;
    margin: 0 0.5rem;
    opacity: 1;
    overflow: hidden;
    display: list-item;
  }

  hr{
    opacity: .15;
  }