@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

body {
  scroll-behavior: smooth;
  margin: 0;
  font-family: 'Quicksand' !important;
  font-size: 14px;
  font-weight: 600;
  min-height: 100vh !important;
  /* background-color: #f9f9f9 !important; */
  background-color: #fff !important;
}

a,
a:hover,
a:focus {
  color: inherit;

}

.app-wrapper {
  /* display: flex; */
  /* align-items: flex-start; */
}

.main-panel {
  width: 100%;
  height: 100vh;
}

.main-panel-content {
  padding: 20px;
  /* width: 100%; */
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 100px);
}

/* #main-wrapper {
    transition: all 0.25s ease-in;
    overflow: hidden;
    position: relative;
  } */

/* ---------------------------------------------------
        Scroll Bar  STYLE
    ----------------------------------------------------- */

/* width */

* {
  /* IE 10+ */
  /* -ms-overflow-style: none; */
  /* Firefox */

  scrollbar-width: thin;
  scrollbar-color: #506070 #e8ecef;
}


::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  cursor: pointer;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e8ecef;
  border-right: 0px solid transparent;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #506070;
  border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #fdc208;
}

/* Handle on hover */

::-moz-scrollbar-thumb:hover {
  background: rgb(167, 159, 159);
}

/* width */

::-moz-scrollbar {
  width: 6px;
}

/* Track */

::-moz-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-right: 2px solid #506070;
}

/* Handle */

::-moz-scrollbar-thumb {
  background: #506070;
  border-radius: 10px;
}