/***** Navbar *****/

.pos-top-navbar {
    /* padding: 20px 14px; */
    /*  padding: 25px 25px 25px 14px;*/
    padding: 19px 0px !important;
    /* filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15000000596046448)) !important; */
    /* filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.15000000596046448)); */
    background: #ffff;
    z-index: 999;

    .logo_icon {
        background: #000000;
        border-radius: 50%;
        width: 55px;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;

        img {
            width: 45px;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    &.border-bottom {
        border-bottom: 1px dashed rgba(217, 217, 217, 0.6) !important;
    }

    &.filter-shadow {
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15000000596046448)) !important;
    }

    .navbar-nav {
        .nav-link {
            /* border: 1px solid #D9DBDA; */
            border-radius: 10px;
            /*  width: 46px;*/
            height: 56px;
            /*  height: 46px;*/
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            color: #000;
            // margin-left: 15px;
            padding: 20px 18px;
            font-size: 14px;
        }

        .dropdown {
            .dropdown-toggle {
                padding: 20px 18px;
                /* padding: 0px; */
                display: flex;
                align-items: center;
                color: #CAB05D;
                background: #ffff;
                border-radius: 10px;
                /*  width: 145px;*/

                &:after {
                    content: "\f107";
                    font-family: FontAwesome;
                    border: 0;
                    vertical-align: middle;
                    margin-left: 0.5em;
                    line-height: 1;
                    color: #CAB05D;
                    font-size: 16px;
                    /*  margin: 0 0.5em;*/
                }

            }

            .dropdown-menu {
                position: absolute;
                font-size: 14px;
                font-weight: 600;
                border: 0;
                /* box-shadow: 0 10px 40px 0 rgba(32, 28, 69, 0.1); */
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
                margin-top: 0;
                border-radius: 10px;
                overflow: hidden;

                .dropdown-item {
                    font-size: 14px;
                    font-weight: 600;

                    &:focus,
                    &:hover {
                        /* background-color: transparent; */
                        color: #CAB05D;
                        transition: all 0.3s ease-in-out;
                    }

                    &:active,
                    &.active {
                        background-color: transparent;
                        color: #CAB05D;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }
    }
}

.main_search_bar {
    position: relative;

    .search_bar_list {
        width: 100%;
        border: 0px;
        // border-color: #D9DBDA;
        border-radius: 10px;
        position: absolute;
        z-index: 1055;
        margin-top: .5rem;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
        overflow: hidden;
        overflow-y: auto;
        height: 250px;
        // min-height: auto;
        // max-height: 300px;

        ::-webkit-scrollbar {
            display: none;
        }

        .list-group {

            .list-group-item {
                cursor: pointer;
                color: #000000;
                border-color: #d9dbdab4;

                &.active {
                    color: #CAB05D;
                    background-color: #fff;
                    border-color: #d9dbdab4;
                }
            }
        }
    }
}

/***** Home *****/

.leftProduct-div .card-footer,
.cart-div .card-footer,
.Product-div .card-footer {
    border-top: 1px dashed #d9d9d999 !important;
}


.leftProduct-div {
    overflow: hidden;
    // background: #fff;
    // height: calc(100vh - (0px + 0px));
    height: calc(99vh - (30px + 79px)) !important;
    // height: calc(99vh - (30px + 49px)) !important;
    // height: calc(99vh - (24px + 30px));
    // background: rgba(217, 219, 218, 0.30);

    .card-footer {
        padding-top: 39px !important;
        border: 0px;
    }

    hr {
        opacity: 1;
        border-top: 1px dashed #d9d9d999 !important;
        // margin-bottom: 0px;
    }
}

.leftProduct-div-empty {
    overflow: hidden;
    overflow-y: auto;
    height: calc(99vh - (40px + 231px)) !important;
    // height: calc(99vh - (40px + 170px)) !important;
}

.Product-div {
    overflow: hidden;
    // background: #F2F7FD;
    background: #fff;
    // height: calc(100vh - (0px + 0px));
    height: calc(99vh - (30px + 79px)) !important;
    // height: calc(99vh - (30px + 49px)) !important;
    // height: calc(99vh - (24px + 30px));
    border-color: #d9d9d999;

}

.Product-div-empty {
    overflow: hidden;
    overflow-y: auto;
    height: calc(99vh - (40px + 243px)) !important;
    // height: calc(99vh - (40px + 170px)) !important;
}

.Product-div-cat {
    // border-color: #d9d9d999;
    // background: rgba(217, 219, 218, 0.30);
    background: #fff;
    // overflow: hidden;
    // overflow-y: auto;
    // height: calc(99vh - (40px + 243px)) !important

    .expand_btn {
        padding: 5px 30px;
        font-size: 14px;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 9px !important;
        pointer-events: auto;
        background: transparent !important;
        border: none;
        color: #000 !important;
    }

    .cat_height {
        overflow: hidden;
        overflow-y: auto;
        height: 500px;
        // max-height: calc(100vh - 410px) !important;
        // height: calc(99vh - (500px)) !important;
        // height: calc(99vh - (40px + 170px)) !important;
    }
}

.inner-p {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    gap: 15px;

    .card.p-main-box {
        // max-width: 10rem;
        // max-height: 10rem;
        width: 15%;
        // width: 8.563rem;
        // height: 9.375rem;
        height: 9.700rem;
        border-radius: 10px;
        cursor: pointer !important;
        overflow: hidden;
        background: #F0E8BC;
        border: 0px;
        // margin-bottom: 5px;

        .card-header {
            padding: 0px;
            border: 0px;
            background: transparent;

            .p-img {
                width: 100%;
                height: 5.625rem;
                border-bottom: 1px solid #ffff;
                object-fit: cover;
            }
        }

        .card-body {
            padding: 10px;
        }

        .p-title {
            font-size: 13px;
            font-weight: 600;
            text-align: center;
            // height: 30px;
            height: 35px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            text-transform: capitalize;
            // padding: 0px 5px;
        }

        .p-price {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
        }

        .p-icon {
            font-size: 12px;
            border: 1px solid;
            // padding: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
        }

        .veg-icon {
            border-color: #198754;
            color: #198754;
        }

        .nonveg-icon {
            border-color: #DC3545;
            color: #DC3545;
        }

        .offer-tag {
            background: #F0E8BC;
            padding: 3px 6px;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            position: absolute;
            bottom: 5px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

    }

    .card.p-main-box-small {
        width: 9.5rem;
        height: 3.5rem;
        border-radius: 10px;
        cursor: pointer !important;
        overflow: hidden;
        background: #F0E8BC;
        border: 0px;
        // margin-bottom: 5px;

        .card-header {
            padding: 0px;
            border: 0px;
            background: transparent;

            .p-img {
                width: 100%;
                height: 5.625rem;
                border-bottom: 1px solid #ffff;
            }
        }

        .card-body {
            padding: 10px;
            display: flex;
            align-items: center;
        }

        .p-title {
            font-size: 12px;
            font-weight: 600;
            text-align: start;
            height: 30px;
            word-wrap: break-word;
            // padding: 0px 5px;
        }

        .b-line {
            // padding: 1px;
            margin-right: 4px;
            width: 1px;
            height: 30px;
            background: #ABABAB;
            border-radius: 10px;
            display: block;
        }

        .p-price {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 600;
        }

        .p-icon {
            width: 10px;
            height: 50px;
            position: absolute;
            right: 0;
            bottom: -20px;
            rotate: 45deg;
        }

        .veg-icon {
            background: #198754;
            color: #198754;
        }

        .nonveg-icon {
            background: #DC3545;
            color: #DC3545;
        }

        .offer-tag {
            background: #F0E8BC;
            padding: 3px 6px;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            position: absolute;
            bottom: 5px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

    }

    .card.p-main-box-small-new {
        // width: 8.563rem;
        width: 15%;
        height: 4.5rem;
        border-radius: 10px;
        cursor: pointer !important;
        overflow: hidden;
        // background: #ffff;
        background: #F0E8BC;
        border: 0px;
        margin-bottom: 5px;

        // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        .card-header {
            padding: 0px;
            border: 0px;
            background: transparent;

            .p-img {
                width: 100%;
                height: 5.625rem;
                border-bottom: 1px solid #ffff;
            }
        }

        .card-body {
            padding: 10px;
            display: flex;
            align-items: center;
        }

        .p-title {
            font-size: 12px;
            font-weight: 600;
            // text-align: start;
            text-align: center;
            height: 30px;
            word-wrap: break-word;
            display: flex;
            align-items: end;
            justify-content: center;
        }

        .b-line {
            width: 100%;
            height: 1px;
            background: #ABABAB;
            // background: rgba(217, 219, 218, 0.30);
            border-radius: 10px;
            display: block;
            margin: 5px 0px;
        }

        .p-price {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 600;
        }

        .p-icon {
            width: 10px;
            height: 50px;
            position: absolute;
            right: 0;
            bottom: -17px;
            rotate: 45deg;
        }

        .veg-icon {
            background: #198754;
            color: #198754;
        }

        .nonveg-icon {
            background: #DC3545;
            color: #DC3545;
        }

        .offer-tag {
            background: #F0E8BC;
            padding: 3px 6px;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            position: absolute;
            bottom: 5px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

    }
}

/***** with image card *****/

@media (min-width: 1024px) and (orientation: portrait) {

    .inner-p .card.p-main-box {
        width: 23% !important;
    }

}

@media (min-width: 1181px) and (max-width: 1280px) {

    .inner-p .card.p-main-box {
        width: 18%;
    }

}

@media (min-width: 1025px) and (max-width: 1180px) {

    .inner-p .card.p-main-box {
        width: 18%;
    }

}

@media (min-width: 768px) and (max-width: 1024px) {

    .inner-p .card.p-main-box {
        width: 30%;
    }

}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    .inner-p .card.p-main-box {
        width: 23%;
    }

}

@media (min-width: 430px) and (max-width: 932px) and (orientation: landscape) {

    .inner-p .card.p-main-box {
        width: 30%;
    }

}

@media (min-width: 481px) and (max-width: 767px) {

    .inner-p .card.p-main-box {
        width: 30%;
    }

}

@media (min-width: 320px) and (max-width: 480px) {

    .inner-p .card.p-main-box {
        width: 47%;
    }

}

/***** without image card *****/

@media (min-width: 1024px) and (orientation: portrait) {

    .inner-p .card.p-main-box-small-new {
        width: 18% !important;
    }

}

@media (min-width: 1181px) and (max-width: 1280px) {

    .inner-p .card.p-main-box-small-new {
        width: 15%;
    }

}

@media (min-width: 1025px) and (max-width: 1180px) {

    .inner-p .card.p-main-box-small-new {
        width: 18%;
    }

}

@media (min-width: 768px) and (max-width: 1024px) {

    .inner-p .card.p-main-box-small-new {
        width: 30%;
    }

}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    .inner-p .card.p-main-box-small-new {
        width: 18%;
    }

}

@media (min-width: 430px) and (max-width: 932px) and (orientation: landscape) {

    .inner-p .card.p-main-box-small-new {
        width: 30%;
    }

}

@media (min-width: 481px) and (max-width: 767px) {

    .inner-p .card.p-main-box-small-new {
        width: 30%;
    }

}

@media (min-width: 320px) and (max-width: 480px) {

    .inner-p .card.p-main-box-small-new {
        width: 47%;
    }

}


.cart-div {
    overflow: hidden;
    background: #fff;
    height: calc(99vh - (30px + 79px)) !important;

    .table th {
        white-space: nowrap;
        padding-bottom: 20px;
    }

    .card-header {
        hr {
            opacity: 1;
            border-top: 1px dashed #d9d9d999 !important;
            margin-bottom: 0px;
        }
    }

    .card-footer {
        hr {
            opacity: 1;
            border-top: 1px dashed #d9d9d999 !important;
            // border-top: 1px dashed #D9D9D9 !important;
            margin-top: 0px;
        }
    }

    .empty-cart {
        overflow: auto;
        height: calc(99vh - (40px + 343px)) !important;
    }

    .empty-cart .table-responsive {
        height: 100%;
    }

    .cart-table {
        border: none;
        border-spacing: 0;
        border-collapse: separate;

        tbody,
        td,
        tfoot,
        th,
        thead,
        tr {
            border: none;
        }

        // th:first-child,
        // tr:first-child {
        //     width: 15rem;
        // }

        .orderItem_name {
            width: 15rem;
            font-size: 12px;
            text-transform: capitalize;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-box-orient: vertical;
            // -webkit-line-clamp: 2;
        }

        .orderItem_qty {
            display: flex;
            justify-content: space-between;
            align-items: center;

            input.qtyInput {
                display: flex;
                width: 35px;
                height: 35px;
                background: #fff;
                border-radius: 10px;
                align-items: center;
                justify-content: center;
                color: #000000;
                border: 0px;
                font-size: 14px;
                font-weight: 600;
                text-align: center;
            }

            span {
                display: flex;
                width: 35px;
                height: 35px;
                background: #fff;
                border-radius: 10px;
                align-items: center;
                justify-content: center;
                color: #000000;
                border: 1px solid #D9DBDA;
                font-size: 14px;

            }

            .action-min,
            .action-plus {
                color: #000000;
                border: 1px solid #D9DBDA;
            }

            .action-del {
                color: #DC3545;
                border: 1px solid #DC3545;
            }

        }


    }


}

/***** summary *****/

.summary-bg-red {
    background: linear-gradient(0deg, #EB8888 0%, #ed5575 100%) !important;
    border-color: #ed5575ab !important;
}

.summary-bg-green {
    background: linear-gradient(0deg, #70C584 0%, #198754e0 100%) !important;
    border-color: #3DA856 !important;
}

.summary-bg-yellow {
    background: linear-gradient(0deg, #b2500b 0%, #d39c56 100%) !important;
    border-color: #b2500b !important;
}

.summary-card {
    background: linear-gradient(180deg, #008CBA 0%, #6fadcb 100%);
    border-radius: 10px;
    height: 56px;
    // width: 200px;

    .summary-box {
        padding: 2px 12px;
    }

    .label-text {
        font-weight: 600;
        font-size: 14px;
        color: #F0E8BC;
        display: block;
    }

    .value-text {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        display: flex;
        align-items: center;
        min-width: 120px;

        .icon {
            font-size: 20px;
        }
    }

    img {
        margin-left: 15px;
        width: 34px;
    }
}

/***** Order Type list Button *****/

.order_type_container {

    .order_type.list-group {
        height: 100%;

        .list-group-item {
            background-color: #ffffff;
            border: 1px solid #D9DBDA;
            color: #000;
            padding: 10px;
            border-radius: 10px;
            font-weight: 600;
            font-size: 14px;
            // line-height: 1.5;
            // height: 56px;
            margin-bottom: 15px;
            box-shadow: none;

            &.active {
                color: #000000;
                background-color: #CAB05D;
                border-color: #877849;
            }

            .list_img_one,
            .list_img_two,
            .list_img_three,
            .list_img_four {
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 20px;
                height: 20px;
                display: block;
                transition: all 0.1s ease-in-out;
                margin: 0 auto;
            }

            .list_img_one,
            .active .list_img_one {
                background-image: url('./assets/images/SVG/dineblack.svg');
            }

            .list_img_two,
            .active .list_img_two {
                background-image: url('./assets/images/SVG/deliveryblack.svg');
            }

            .list_img_three,
            .active .list_img_three {
                background-image: url('./assets/images/SVG/packingblack.svg');
            }

            .list_img_four,
            .active .list_img_four {
                background-image: url('./assets/images/SVG/drivethrublack.svg');

            }
        }

    }

    .card {
        background-color: #ffffff;
        border: 1px solid #D9DBDA;
        min-height: 300px;
        border-radius: 10px;

        .card-title {
            font-size: 14px;
            font-weight: 600;
        }

    }
}


/***** Seat Reservation System *****/

.grid_seat {


    td {
        width: 86px;
        height: 70px;
        font-size: 14px;
        font-weight: 600;
        border: 1px solid;
        text-align: center;
        float: left;
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #fff;
        border-color: #fff;
        color: #ABABAB;
        border-radius: 10px;
        cursor: pointer;
        box-shadow: 0 0.8px 16px rgba(0, 0, 0, 0.15);
        position: relative;

        // &:hover {
        //     cursor: pointer;
        //     background-color: #CAB05D;
        //     border-color: #877849;
        //     color: #000;
        // }


        &::before {
            content: "";
            width: 11px;
            height: 11px;
            background: #D9DBDA;
            border-radius: 10px;
            position: absolute;
            top: 5px;
            left: 5px;
        }
    }

    /***** grid_seat td *****/

    .reserved {
        // background-color: #ffc043be !important;
        // border-color: #ffc043 !important;
        color: #ffc043 !important;

        &::before {
            background: #ffc043;
        }

        &::after {
            font-size: 12px;
            content: "Reserved";
        }
    }

    .selected {
        // background-color: #0cc165 !important;
        // border-color: #198754 !important;
        color: #0cc165 !important;

        &::before {
            background: #0cc165;
        }

        &::after {
            font-size: 12px;
            content: "Selected";
        }
    }

    .available {

        &::before {
            background: #ABABAB;
        }

        &::after {
            font-size: 12px;
            content: "Available";
        }
    }

    .bg-warning {
        background: #ffc043be !important;
    }

    .bg-success {
        background: #0cc165 !important;
    }

    .bg-secondary {
        background: #ABABAB !important;
    }

}




/******* home new search box *******/

.form-input {
    background-color: #ffffffff;
    border: none;
    border-radius: 10px;
    /* border-radius: calc(0.5 * 70px); */
    color: #000000ff;
    outline: 0;
    padding-right: 45px;
    padding-left: 42px;
    position: relative;
    width: 100%;
    height: 40px;
    box-shadow: none;
    text-transform: capitalize;
    text-indent: 25px;
}

.searchIcon {
    left: 8px;
    position: absolute;
    top: 0px;
    z-index: 1;
    border: none !important;
    background: transparent !important;
    color: #000000 !important;
    padding: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    box-shadow: none !important;
}

.searchBox {
    position: relative;
    width: 100%;
}

.formInput {
    text-indent: 30px;
    font-size: 14px;
}

.formInput::placeholder {
    // color: #adadad !important;
    /* text-transform: lowercase; */
}

/***** Payment Type *****/

.input_info_icon {
    position: relative;

    .info_icon {
        position: absolute;
        position: absolute;
        right: 10px;
        top: 16px;
        color: #D9DBDA !important;
        font-size: 24px;
    }
}

/***** Veg and Non-Veg Symbol  *****/

.symbol {
    display: flex;
    align-items: center;

    &::before {
        position: relative;
        content: '';
        margin-right: 10px;
        display: flex;
        width: 4px;
        height: 12px;
        border-radius: 10px;
    }

    &.symbol_veg::before {
        background: #198754;
    }

    &.symbol_nonVeg::before {
        background: #DC3545;
    }
}

.td-disabled {
    opacity: 0.65;
    pointer-events: none;
}