/********* Sidebar ***********/

#sidebar {
    position: relative;
    /* width: 220px; */
    width: max-content;
    float: left;
    display: block;



    .ps-submenu-content {
        border-radius: 10px;
        /* margin-left: 8px !important; */
    }


    .ps-sidebar-root {
        position: relative;
        border: none;
        -webkit-transition: width, left, right, 300ms;
        transition: width, left, right, 300ms;
        width: 265px;
        min-width: 265px;

        &.ps-collapsed {
            width: 100px;
            min-width: 100px;
        }

        .ps-sidebar-container {
            overflow: unset;
            background-color: white;
            filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.15000000596046448)) !important;
            // box-shadow: 0px 0px 10px rgba(120, 130, 140, 0.13);

            .ps-menu-root.sidebar_logo {

                .main_logo {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    transition: all 0.4s ease-in-out;
                    padding: 19px;

                    .ps-menu-button {
                        padding: 0;
                        height: unset;
                        cursor: unset;

                        &:hover {
                            background: transparent;
                        }

                        .ps-menu-label {
                            display: flex;
                            align-items: center;
                            justify-content: start;
                        }
                    }
                }

                .logo_icon {
                    background: #000000;
                    border-radius: 50%;
                    width: 55px;
                    height: 55px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.3s ease-in-out;
                    overflow: hidden;

                    img {
                        width: 45px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }

                .logo_text {
                    font-size: 1.2rem;
                    // font-size: 1.75rem;
                    color: #000;
                    font-weight: bold;
                    transition: all 0.4s ease-in-out;
                    margin-left: 10px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 66%;

                }
            }

            .ps-menu-root.sidebar_content {
                height: 100vh;
                overflow: hidden;
                overflow-y: scroll;
                max-height: calc(100vh - 97px);
                -ms-overflow-style: none;
                /* IE 11 */
                scrollbar-width: none;
                /* Firefox 64 */
                padding: 19px;

                &::-webkit-scrollbar {
                    display: none;
                    -ms-overflow-style: none;
                    /* IE 11 */
                    scrollbar-width: none;
                    /* Firefox 64 */
                }

                .ps-menuitem-root>.ps-menu-button:hover {
                    /* color: #CAB05D; */
                    color: #000;
                    background: rgba(240, 232, 188, 0.5);
                    /* background: transparent; */
                    transition: all 0.3s ease-in-out;
                }

                li.ps-menuitem-root {
                    margin-bottom: 10px;
                }

                .ps-menuitem-root.ps-active>.ps-menu-button,
                .ps-menuitem-root.ps-open>.ps-menu-button {
                    background-color: #CAB05D;
                    border-color: #877849;
                    color: #000;
                    border-radius: 10px;
                }

                .ps-menuitem-root>.ps-menu-button {
                    font-size: 14px;
                    border-radius: 10px;
                    padding: 10px 14px;
                    height: 56px;
                }

                .ps-menuitem-root ul .ps-menu-button {
                    padding-right: 20px;
                    padding-left: 52px;
                    height: 40px;
                }

                .ps-menuitem-root ul .ps-menu-button:hover,
                .ps-submenu-content .ps-menuitem-root .ps-menu-button.ps-active {
                    /* color: #CAB05D; */
                    color: #000;
                    background: rgba(240, 232, 188, 0.5);
                    /* background: transparent; */
                    transition: all 0.3s ease-in-out;
                }

                .ps-menuitem-root .ps-submenu-content.css-wlra1l {
                    margin-left: 10px !important;
                }

                .ps-menuitem-root .ps-submenu-content.css-wlra1l .ps-menu-button {
                    padding-right: 20px;
                    padding-left: 20px;
                    /* height: 40px; */
                }

                .ps-menuitem-root .ps-submenu-content.css-18unl23 li,
                .ps-menuitem-root .ps-submenu-content.css-wlra1l li {
                    margin-bottom: 0px;
                }

            }
        }

    }

    .ps-submenu-expand-icon.ps-open.css-um1o6k {
        right: 15px;
    }
}


@media (max-width: 720px) {
    #sidebar .ps-sidebar-root.ps-collapsed {
        width: 0px !important;
        min-width: 0px !important;
        margin-left: -50px;
    }

    #sidebar .ps-sidebar-root .ps-sidebar-container .ps-menu-root .main_logo {
        display: none !important;
    }

    #sidebar .ps-sidebar-root {
        /* height: 100vh; */
        /* width: 275px; */
        position: absolute;
        /* top: 87px; */
        top: 97px;
        /* max-height: calc(100% - 90px); */

    }
}

@media (max-width: 1024px),
(max-width: 1180px) {

    #sidebar .ps-sidebar-root {
        /* height: 100vh; */
        /* width: 275px; */
        // position: absolute;
        /* top: 87px; */
        // top: 97px;
        /* max-height: calc(100% - 90px); */

    }

    #sidebar .ps-sidebar-root .ps-sidebar-container .ps-menu-root .main_logo {
        // display: none !important;
    }

    // #sidebar .ps-sidebar-root .ps-sidebar-container .ps-menu-root.sidebar_content {
    //     padding: 0px;
    // }

}



/***** Navbar *****/

.admin-top-navbar {
    padding: 19px !important;
    filter: drop-shadow(10px 4px 10px rgba(0, 0, 0, 0.15000000596046448)) !important;
    /* filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.15000000596046448)); */
    background: #ffff;
    z-index: 999;

    .navbar-toggler {
        box-shadow: none;
        border: none;
        font-size: 16px;
    }

    @media (max-width: 720px) {
        .logo_icon {
            display: flex !important;
        }
    }

    .logo_icon {
        background: #000000;
        border-radius: 50%;
        width: 55px;
        height: 55px;
        display: none;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;
        overflow: hidden;

        img {
            width: 45px;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    &.border-bottom {
        border-bottom: 1px dashed rgba(217, 217, 217, 0.6) !important;
    }

    &.filter-shadow {
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15000000596046448)) !important;
    }

    .navbar-nav {
        .nav-link {
            border: 1px solid #D9DBDA;
            border-radius: 10px;
            /*  width: 46px;*/
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            color: #000;
            // margin-left: 15px;
            padding: 20px 18px;
            font-size: 14px;
        }

        .dropdown {
            .dropdown-toggle {
                // padding: 20px 18px;
                padding: 14px 18px;
                display: flex;
                align-items: center;
                color: #000;
                background: #D9DBDA;
                border-radius: 10px;
                /*  width: 145px;*/
                font-size: 14px;
                font-weight: 600;

                &:after {
                    content: "\f107";
                    // content: "\f142";
                    font-family: FontAwesome;
                    border: 0;
                    vertical-align: middle;
                    margin-left: 0.5em;
                    line-height: 1;
                    color: #000;
                    font-size: 16px;
                    /*  margin: 0 0.5em;*/
                }

            }

            .dropdown-menu {
                position: absolute;
                font-size: 14px;
                font-weight: 600;
                border: 0;
                /* box-shadow: 0 10px 40px 0 rgba(32, 28, 69, 0.1); */
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
                margin-top: 0;
                border-radius: 10px;
                overflow: hidden;

                .dropdown-item {
                    font-size: 14px;
                    font-weight: 600;

                    &:focus,
                    &:hover {
                        /* background-color: transparent; */
                        color: #CAB05D;
                        transition: all 0.3s ease-in-out;
                    }

                    &:active,
                    &.active {
                        background-color: transparent;
                        color: #CAB05D;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }
    }
}

/***** Veg and Non-Veg Symbol  *****/

.symbol {
    display: flex;
    align-items: center;

    &::before {
        position: relative;
        content: '';
        margin-right: 10px;
        display: flex;
        width: 4px;
        height: 12px;
        border-radius: 10px;
    }

    &.symbol_veg::before {
        background: #198754;
    }

    &.symbol_nonVeg::before {
        background: #DC3545;
    }
}


/***** Cards  *****/

.inso-gradient-card {
    background: linear-gradient(to right, #CAB05D 0%, #F0E8BC 100%);
    background: -webkit-linear-gradient(to right, #CAB05D 0%, #F0E8BC 100%);
    background: -moz-linear-gradient(to right, #CAB05D 0%, #F0E8BC 100%);
}

.inso-card {
    // margin-bottom: 1.25rem;
    background-color: #fff;
    transition: all .5s ease-in-out;
    position: relative;
    border: 0px solid transparent;
    border-radius: 10px;
    /* box-shadow: 0px 12px 23px 0px rgba(62, 63, 122, 0.04); */
    // height: calc(100% - 30px);
    overflow: hidden;
    border: 1px solid #D9DBDA;

    &.card-height {
        height: calc(100% - 30px);
    }

    .card-body {
        padding: 1.25rem;
    }

    .inso-card-img {
        background: #ffff;
        width: 68px;
        height: 68px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: '';
            position: absolute;
            width: 84px;
            height: 84px;
            border-radius: 50%;
            border: 4px solid #ffff;
        }
    }

}

.items-detail {
    border-radius: 0.625rem;
    border: 1px solid #D9DBDA;
}


/***** hambuger-menu (Bar-icon)  *****/

:root {
    --animation-curve: cubic-bezier(1, 0, 0, 1);
    --bar-width: 16px;
    --bar-height: 2px;
    --hamburger-menu-gap: 2px;
    --foreground: #000;
    --background: #ffff;
    --hambuger-margin: 10px;
    --hambuger-padding: 20px;
    --animation-timing: 200ms ease-in-out;
    --hambuger-height: calc(var(--bar-height) * 3 + var(--hamburger-menu-gap) * 2);
}

.hamburger-menu {
    --x-width: calc(var(--hambuger-height) * 1.41421356237);
    display: flex;
    flex-direction: column;
    gap: var(--hamburger-menu-gap);
    width: 30px;
    /* position: absolute; */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /* top: var(--hambuger-margin); */
    /* left: var(--hambuger-margin); */
    z-index: 10;
    cursor: pointer;
    /* padding: var(--hambuger-padding); */
    background: #fdc40800;
    border-radius: 50%;
    /* margin-left: -3px; */
    height: 47px;
    align-items: center;
    justify-content: center;

    &::before,
    &::after,
    input {
        content: "";
        width: var(--bar-width);
        height: var(--bar-height);
        background-color: var(--foreground);
        border-radius: 9999px;
        transition: opacity var(--animation-timing), width var(--animation-timing),
            rotate var(--animation-timing), translate var(--animation-timing),
            background-color var(--animation-timing);
        transform-origin: left center;
    }

    input {
        appearance: none;
        padding: 0;
        margin: 0;
        outline: none;
        pointer-events: none;
    }

    &:has(input:checked)::before {
        rotate: 45deg;
        width: 11px;
        translate: 0 calc(6px / -2);
        margin-right: -14px;
    }

    &:has(input:checked)::after {
        rotate: -45deg;
        width: 11px;
        translate: 0 calc(6px / 2);
        margin-right: -14px;
    }

    input:checked {
        opacity: 1;
    }
}



/***** slider  *****/


@media (min-width: 320px) and (max-width: 1024px) {
    .slider_width {
        width: 1000px;
    }
}

@media (min-width: 1025px) {
    .slider_width {
        width: 800px;
    }
}


.admin-nav-tabs {
    // display: inline-flex !important;
    display: block;
    flex-wrap: nowrap;
    overflow-x: visible;
    font-size: 14px;
    border: none;
    width: 90%;
    margin: 0 auto;

    &::-webkit-scrollbar {
        display: none;
    }

    .nav-link {
        border-radius: 0;
        background: transparent;
        padding: 18px 15px 10px;
        font-weight: 600;
        border-bottom: 1px solid #D9DBDA !important;
        font-size: 14px;
        border: 0;
        color: #ABABAB;
        position: relative;
        // min-width: 140px;
        height: 56px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 0px;
            width: 100%;
            background: #CAB05D;
            content: "";
        }

        &.active {
            color: #000;

            &:after {
                height: 3px;
            }
        }
    }


    .slick-prev,
    .slick-next {
        z-index: 1;
        width: 35px;
        height: 35px;

        &:before {
            color: #000 !important;
            font-size: 16px !important;
            font-weight: bold;
            font-family: FontAwesome !important;
        }
    }

    .slick-prev {
        left: -40px !important;

        &:before {
            content: '\f053' !important;
        }
    }

    .slick-next {
        right: -40px !important;

        &:before {
            content: '\f054' !important;
        }
    }

}


//  .item-nav-tabs .nav-tabs .nav-link,
// .item-nav-tabs .nav-link {
//     text-align: center;
//     border: none !important;
//     color: #ABABAB;
//     white-space: nowrap;
//     background: #fff;
//     border-bottom: 3px solid #d9dbda00 !important;
// }

// .item-nav-tabs .nav-item.show .nav-link,
// .item-nav-tabs .nav-link.active {
//     color: #000;
//     border-bottom: 3px solid #CAB05D !important;
// }

.left-side {
    overflow: hidden;
    overflow-y: scroll;
    height: calc(99vh - 210px) !important;
    // height: calc(99vh - 280px) !important;
    // height: calc(99vh - 207px) !important;
}


.right-side {
    overflow: hidden;
    height: calc(99vh - 210px) !important;
    // height: calc(99vh - 280px) !important;
    // height: calc(99vh - 207px) !important;
    // margin-bottom: 1.25rem;
    background-color: #fff;
    transition: all .5s ease-in-out;
    position: relative;
    border: 0px solid transparent;
    border-radius: 10px;
    border: 1px solid #D9DBDA;

    .card-header {
        padding: 1rem;

        hr {
            opacity: 1;
            border-top: 1px dashed #d9d9d999 !important;
            margin-bottom: 0px;
        }
    }


    .card-body {
        padding: 1rem;
        overflow: auto;
        height: calc(99vh - 414px) !important;

        .table-responsive {
            height: 100%;
        }

        .table {
            border: none;
            border-spacing: 0;
            border-collapse: separate;

            tbody,
            td,
            tfoot,
            th,
            thead,
            tr {
                border: none;
            }


            th {
                white-space: nowrap;
                padding-bottom: 20px;
            }


            // th:first-child,
            // tr:first-child {
            //     width: 15rem;
            // }

            .orderItem_name {
                width: 15rem;
                font-size: 12px;
            }

            .orderItem_qty {
                display: flex;
                justify-content: space-between;
                align-items: center;

                input.qtyInput {
                    display: flex;
                    width: 35px;
                    height: 35px;
                    background: #fff;
                    border-radius: 10px;
                    align-items: center;
                    justify-content: center;
                    color: #000000;
                    border: 0px;
                    font-size: 14px;
                    font-weight: 600;
                    text-align: center;
                }

                span {
                    display: flex;
                    width: 35px;
                    height: 35px;
                    background: #fff;
                    border-radius: 10px;
                    align-items: center;
                    justify-content: center;
                    color: #000000;
                    border: 1px solid #D9DBDA;
                    font-size: 14px;

                }

                .action-min,
                .action-plus {
                    color: #000000;
                    border: 1px solid #D9DBDA;
                }

                .action-del {
                    color: #DC3545;
                    border: 1px solid #DC3545;
                }

            }


        }
    }



    .card-footer {
        padding: 1rem;

        hr {
            opacity: 1;
            border-top: 1px dashed #d9d9d999 !important;
            // border-top: 1px dashed #D9D9D9 !important;
            margin-top: 0px;
        }

    }

}


.orderItem_qty {
    display: flex;
    justify-content: space-between;
    align-items: center;

    input.qtyInput {
        display: flex;
        width: 35px;
        height: 35px;
        background: #fff;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        color: #000000;
        border: 0px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
    }

    span {
        display: flex;
        width: 35px;
        height: 35px;
        background: #fff;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        color: #000000;
        border: 1px solid #D9DBDA;
        font-size: 14px;

    }

    .action-min,
    .action-plus {
        color: #000000;
        border: 1px solid #D9DBDA;
    }

    .action-del {
        color: #DC3545;
        border: 1px solid #DC3545;
    }

}



.floating-input-group {

    &.input-group>.form-control:focus {
        z-index: 1;
    }

    .input-group-text {
        font-size: 14px;
        font-weight: 600;
        border-color: #D9DBDA !important;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}